*{
  box-sizing: border-box;
}
html, body, #root{
  /* height: 100%; */
  width: 100%;
  /* overflow: hidden; */
}
body {
  margin: 0;
  padding: 0;
}